<template>
    <div class="contacts full-width" v-if="pageTemplate && pageTemplate.contactsFull">
        <div class="contacts__main-image">
            <div class="contacts__main-image__container">
                <div class="contacts__main-image__container__icon" />
                <div class="contacts__main-image__container__text">
                    <div class="contacts__main-image__container__title">{{ pageTemplate.contactsFull.pageTitle[lang] }}</div>
                    <div class="contacts__main-image__container__comment">{{ pageTemplate.contactsFull.pageSubtitle[lang] }}</div>
                </div>
            </div>
        </div>
        <div class="contacts__container">
            <div class="contacts__info">
                <div class="contacts__info__key">{{ pageTemplate.contactsFull.addressTitle[lang] }}</div>
                <div class="contacts__info__value">{{ pageTemplate.contactsFull.address[lang] }}</div>
                <div class="contacts__info__key">{{ pageTemplate.contactsFull.phoneTitle[lang] }}</div>
                <div class="contacts__info__value">{{ pageTemplate.contactsFull.phone[lang] }}</div>
                <div class="contacts__info__key">{{ pageTemplate.contactsFull.emailTitle[lang] }}</div>
                <div class="contacts__info__value"><a :href="`mailto:${ pageTemplate.contactsFull.email[lang] }`">{{ pageTemplate.contactsFull.email[lang] }}</a></div>
                <div class="contacts__info__key">{{ pageTemplate.contactsFull.workTitle[lang] }}</div>
                <div class="contacts__info__value">{{ pageTemplate.contactsFull.work[lang] }}</div>
                <div class="contacts__info__key">{{ pageTemplate.contactsFull.requisitesTitle[lang] }}</div>
                <div class="contacts__info__value" v-html="pageTemplate.contactsFull.requisites[lang]"></div>
                <div class="contacts__info__key">{{ pageTemplate.contactsFull.socialTitle[lang] }}</div>
                <div class="contacts__info__social">
                    <a
                        v-if="pageTemplate.contactsFull.fb[lang]"
                        :href="pageTemplate.contactsFull.fb[lang]"
                        target="_blank">
                        <div class="contacts__info__social__item facebook"></div>
                    </a>
                    <!-- <a
                        v-if="pageTemplate.contactsFull.twitter[lang]"
                        :href="pageTemplate.contactsFull.twitter[lang]"
                        target="_blank">
                        <div class="contacts__info__social__item twitter"></div>
                    </a> -->
                    <a
                        v-if="pageTemplate.contactsFull.instagram[lang]"
                        :href="pageTemplate.contactsFull.instagram[lang]"
                        target="_blank">
                        <div class="contacts__info__social__item instagram"></div>
                    </a>
                    <!-- <a
                        v-if="pageTemplate.contactsFull.google[lang]"
                        :href="pageTemplate.contactsFull.google[lang]"
                        target="_blank">
                        <div class="contacts__info__social__item google"></div>
                    </a> -->
                    <a
                        v-if="pageTemplate.contactsFull.vk[lang]"
                        :href="pageTemplate.contactsFull.vk[lang]"
                        target="_blank">
                        <div class="contacts__info__social__item VK"></div>
                    </a>
                    <a
                        v-if="pageTemplate.contactsFull.telegram[lang]"
                        :href="pageTemplate.contactsFull.telegram[lang]"
                        target="_blank">
                        <div class="contacts__info__social__item telegram"></div>
                    </a>
                </div>
            </div>
            <div class="contacts__form">
                <div class="contacts__form__title">{{ pageTemplate.contactsFull.formTitle[lang] }}</div>
                <FormContact />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';

    import BaseButton from '../components/common/BaseButton.vue';
    import FormContact from '../components/common/FormContact.vue';

    import moment from '@/plugins/moment';
    moment.locale('ru');

    export default {
        name: 'Contacts',
        metaInfo() {
            return {
                title: this.lang === 'ru' ? 'Контакты' : 'Contacts'
            }
        },
        components: {
            BaseButton,
            FormContact
        },
        data: () => ({
            isDesktop: false,
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('pageTemplates', {
                pageTemplate: state => state.contacts
            }),
        },
        created() {
            this.isDesktop = !isMobile();
        },
        methods: {
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
        }
    };
</script>

<style lang="scss">
.contacts {
    width: 100%;
    &__main-image {
        position: relative;
        width: 100%;
        height: 335px;
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        overflow: hidden;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
        }
        &__container {
            position: absolute;
            width: 100%;
            height: 335px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            @media all and (max-width: 768px) {
                height: 356px;
            }
            &__icon {
                width: 55px;
                height: 55px;
                margin-bottom: 12px;
                background: url(../assets/brand/icon.png);
                @media all and (max-width: 768px) {
                    margin-bottom: 0;
                }
            }
            &__text {
                display: flex;
                flex-direction: column;
                width: 1280px;
                @media all and (max-width: 768px) {
                    width: 100%;
                    padding: 20px;
                    margin: 0 auto;
                }
            }
            &__title {
                font-weight: bold;
                font-size: 52px;
                // margin-top: 245px;
                line-height: 63px;
                letter-spacing: -0.02em;
                text-align: center;
                margin-bottom: 12px;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    margin-bottom: 20px;
                }
            }
            &__comment {
                font-size: 18px;
                line-height: 180%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                }
            }
        }
    }
    &__container {
        max-width: 1156px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 80px auto;
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin: 60px auto;
            padding: 0 20px;
        }
    }
    &__info {
        width: 520px;
        text-align: left;
        @media all and (max-width: 768px) {
            width: 100%;
        }
        &__key {
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color:  #273155;
            margin-bottom: 8px;
            @media all and (max-width: 768px) {
                margin-bottom: 5px;
            }
        }
        &__value {
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #616D89;
            margin-bottom: 20px;
            > a {
                text-decoration: none;
            }
            @media all and (max-width: 768px) {
                margin-bottom: 15px;
                font-size: 16px;
                line-height: 140%;
            }
        }
        &__social {
            display: flex;
            flex-direction: row;
            &__item {
                width: 32px;
                height: 32px;
                background-position: center;
                background-repeat: no-repeat;
                // border: 1px solid #93A3B4;
                // border-radius: 50%;
                // box-sizing: border-box;
                margin-right: 12px;
                margin-top: 11px;
                cursor: pointer;
                @media all and (max-width: 768px) {
                    margin-top: 0;
                }
                &:last-of-type {
                    margin-right: none;
                }
                &.facebook {
                    background-image: url(../assets/contacts/FacebookNegative.svg);
                }
                &.twitter {
                    background-image: url(../assets/contacts/TwitterNegative.svg);
                }
                &.google {
                    background-image: url(../assets/contacts/GoogleNegative.svg);
                }
                &.VK{
                    background-image: url(../assets/contacts/VKNegative.svg);
                }
                &.telegram {
                    background-image: url(../assets/Telegram.svg);
                }
                &.instagram {
                    background-image: url(../assets/contacts/InstagramNegative.svg);
                }
            }
        }
    }
    &__form{
        &__title{
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #273155;
            margin: 44px 0 28px;
            @media all and (max-width: 768px) {
                margin: 40px 0 20px;
            }
        }
    }
}

</style>